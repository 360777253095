import React from "react";
import { Link } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import contact1 from "../images/about1.jpg";
import { FaEnvelope, FaMapMarkedAlt } from 'react-icons/fa';
import { MdPhone } from 'react-icons/md';
import "../css/plans.css";

class ContactPage extends React.Component {
  render(){

    return (
      <Layout>
        <SEO title="Contact Us" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4">Contact Us</h1>
            </div>
          </div>
          <div className="row mt-4 mb-4">
            <div className="col-sm-8">

            <p className="text-muted">We would love to hear from you! Send us a message to get in contact with us.</p>

            <form name="contact" data-netlify-honeypot="bot-field" action="/contact-success"  method="POST" data-netlify="true">

                <div className="hidden">
                  <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                </div>

                <input type="hidden" name="form-name" value="contact" />

                <div className="form-group">
                  <label htmlFor="contactName">Name</label>
                  <input type="text" name="name" className="form-control" id="contactName" placeholder="Enter your name"/>
                </div>

                <div className="form-group">
                  <label htmlFor="emailAddress">Email address</label>
                  <input type="email" name="email" className="form-control" id="emailAddress" aria-describedby="emailHelp" placeholder="Enter email"/>
                </div>

                <div className="form-group">
                  <label htmlFor="messageInput">Message</label>
                  <textarea name="message" className="form-control" id="messageInput" rows="3"></textarea>
                </div>


                <button type="submit" className="btn btn-success btn-lg float-right mb-4">SEND</button>

            </form>

            </div>
            <div className="col-sm-4">

              <div className="list-group">
                <div className="list-group-item list-group-item-action">
                  <h5>Heritage Craft Homes</h5>
                </div>
                <a href="https://www.google.com/maps/place/Heritage+Craft+Homes/@41.3012081,-112.0849258,18z/data=!4m14!1m7!3m6!1s0x875312d00e1d550d:0xc69868b799cc71fa!2s2396+N+4350+W,+Plain+City,+UT+84404!3b1!8m2!3d41.3017904!4d-112.084148!3m5!1s0x875312c5f5005679:0x7ed2b4a111da08a9!8m2!3d41.3012419!4d-112.0843197!16s%2Fg%2F11g6xwyczf?hl=en" className="list-group-item d-flex justify-content-between align-items-center">
                  <div>2396 N 4350 W<br />
                  Plain City, UT 84404</div>
                <FaMapMarkedAlt className="contact-icons" />
                </a>
                <a href="tel:8013884091" className="list-group-item d-flex justify-content-between align-items-center">
                  (801) 388-4091
                  <MdPhone className="contact-icons" />
                </a>
                <a href="mailto:info@heritagecrafthomes.com" className="list-group-item d-flex justify-content-between align-items-center">
                  <div>info@heritagecrafthomes.com</div>
                  <FaEnvelope className="contact-icons" />
                </a>
              </div>

              <div className="row mt-3">
                <div className="col-sm">
                  <p>Have a warranty service request? Please fill out <Link to="/contact-warranty">this form</Link>.</p>
                </div>
              </div>


            </div>
          </div>

        </div>

      </Layout>
    )
  }
};

export default ContactPage;
